import Swiper from "swiper";
import {Navigation, FreeMode, Mousewheel, Pagination, Autoplay, Parallax} from 'swiper/modules';
import {initCursorForSwiper} from '_swiper_cursor.init.js';

function getFluidGridGutterWidth() {
  const rootContainerElement = document.querySelector('.container'),
    gridGutterProperty = rootContainerElement ? getComputedStyle(rootContainerElement).getPropertyValue('padding-right') : false;

  return gridGutterProperty ? Number(gridGutterProperty.replace('px', '').replace('rem', '')) * 2 : 24;
}

function freezeSwiperBullets(swiperInstance) {
  const hasBullets = swiperInstance.pagination && swiperInstance.pagination.bullets;
  if (!hasBullets) return;

  swiperInstance.pagination.bullets.forEach((bulletPoint, index) => {
    if (index === swiperInstance.realIndex) bulletPoint.classList.add('is--frozen');
    else bulletPoint.classList.remove('is--frozen');
  })

  swiperInstance.el.style.setProperty("--progress", '0');
}

function getSwiperType(swiperElement) {
  return swiperElement.getAttribute('data-swiper');
}

function getDefaultOptions(swiperElement) {
  return {
    direction: 'horizontal',
    speed: 1000,
    updateOnResize: false,

    freeMode: {
      enabled: true,
      sticky: false,
      momentumBounce: false
    },
    mousewheel: {
      enabled: true,
      forceToAxis: true
    },
    navigation: {
      prevEl: `.swiper-navigation[data-swiper-navigation="${getSwiperType(swiperElement)}"] .swiper-button-prev`,
      nextEl: `.swiper-navigation[data-swiper-navigation="${getSwiperType(swiperElement)}"] .swiper-button-next`
    },
  }
}

const swiperSpecificOptions = {

  squareBoxesOptions: swiperElement => {
    return {
      modules: [Navigation, Mousewheel, FreeMode],

      ...getDefaultOptions(swiperElement),
      spaceBetween: 0,

      breakpoints: {
        1400: {
          slidesPerView: 4,
        },
        992: {
          slidesPerView: 3,
        },
        576: {
          slidesPerView: 2
        },
        320: {
          slidesPerView: 1.125
        }
      },
    }
  },

  rectangleBoxesOptions: swiperElement => {
    return {
      modules: [Navigation, Mousewheel, FreeMode],

      ...getDefaultOptions(swiperElement),
      spaceBetween: 0,
      breakpoints: {
        1400: {
          slidesPerView: 2,
        },
        992: {
          slidesPerView: 1.5,
        },
        576: {
          slidesPerView: 1.25
        },
        320: {
          slidesPerView: 1
        }
      },
    }
  },

  fullImageOptions: swiperElement => {
    return {
      modules: [Navigation, Mousewheel, FreeMode, Autoplay, Parallax, Pagination],

      ...getDefaultOptions(swiperElement),
      parallax: true,
      spaceBetween: 0,
      slidesPerView: 1,
      loop: true,
      watchSlidesProgress: true,

      freeMode: {
        enabled: false,
      },

      autoplay: {
        delay: 5000,
        disableOnInteraction: true,
        waitForTransition: true,
      },

      pagination: {
        enabled: true,
        clickable: true,
        el: '.swiper-pagination',
        bulletActiveClass: 'is--active',
        type: 'bullets',
        renderBullet: (index, className) => {
          return '<span class="' + className + '"></span>';
        }
      },

      on: {
        afterInit: function () {
          this.autoplay.stop();
          new IntersectionObserver((entries, observer) => {
            if (entries[0].isIntersecting) this.autoplay.start();
            else this.autoplay.stop();
          }).observe(this.el);
        },

        slideChange: function () {
          this.pagination.bullets.forEach(item => item.classList.remove('is--frozen'));
        },

        realIndexChange: function () {
          const swiperCategories = this.el.querySelectorAll('[data-swiper-navigation] [data-slide-id]');
          swiperCategories.forEach(category => {
            if (Number(category.getAttribute('data-slide-id')) === Number(this.realIndex)) category.classList.add('is--active');
            else category.classList.remove('is--active');
          })
        },

        autoplayTimeLeft: function (swiperInstance, timeLeft, percentage) {
          swiperInstance.el.style.setProperty("--progress", 1 - percentage);
        },

        autoplayStop: function () {
          freezeSwiperBullets(this)
        },

        autoplayStart: function () {
          this.pagination.bullets.forEach((bulletPoint, index) => bulletPoint.classList.remove('is--frozen'));
        },

        navigationNext: function () {
          this.autoplay.stop();
        },

        navigationPrev: function () {
          this.autoplay.stop();
        }
      }
    }
  },

  lessTeasersOptions: swiperElement => {
    return {
      modules: [Navigation, Mousewheel, FreeMode],

      ...getDefaultOptions(swiperElement),
      spaceBetween: getFluidGridGutterWidth(),
      updateOnResize: true,

      breakpoints: {
        992: {
          slidesPerView: 2,
        },
        576: {
          slidesPerView: 1.25
        },
        320: {
          slidesPerView: 1.125
        }
      },
    }

  },

  manyTeasersOptions: swiperElement => {
    return {
      modules: [Navigation, Mousewheel, FreeMode],

      ...getDefaultOptions(swiperElement),
      updateOnResize: true,

      spaceBetween: getFluidGridGutterWidth(),
      breakpoints: {
        992: {
          slidesPerView: 3,
        },
        576: {
          slidesPerView: 1.25,
        },
        320: {
          slidesPerView: 1.125
        }
      },
    }
  }
}

function getRecentOptions(swiperOptionsObject, swiperElement) {
  return swiperOptionsObject[`${getSwiperType(swiperElement)}Options`](swiperElement);
}

export function initSwiper() {
  const swiperElements = document.querySelectorAll('.swiper[data-swiper]');
  swiperElements.forEach(swiperElement => {

    const swiperType = getSwiperType(swiperElement),
      swiperInstance = new Swiper(`[data-swiper="${swiperType}"]`, getRecentOptions(swiperSpecificOptions, swiperElement));

    if (!!swiperInstance.pagination) {
      const swiperPaginationBullets = swiperInstance.pagination.bullets
      swiperPaginationBullets.forEach(paginationBullet => {
        paginationBullet.addEventListener('click', () => {
          swiperPaginationBullets.map(swiperBullet => swiperBullet.classList.remove('is--frozen'));
          paginationBullet.classList.add('is--frozen');
        })
      })
    }

    initCursorForSwiper('.swiper-cursor', '.swiper[data-swiper]');

    if (!!swiperInstance.passedParams.updateOnResize) {
      window.addEventListener('resize', () => {
        swiperInstance.params.spaceBetween = getFluidGridGutterWidth();
        swiperInstance.update();
      });
    }
  })
}
