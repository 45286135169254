export function parseCubicBezier(easeString) {
  const [x1, y1, x2, y2] = easeString
    .replace(/cubic-bezier|\(|\)/g, '')
    .split(',')
    .map(parseFloat);

  function cubicBezier(t) {
    const cx = 3.0 * x1;
    const bx = 3.0 * (x2 - x1) - cx;
    const ax = 1.0 - cx - bx;
    const cy = 3.0 * y1;
    const by = 3.0 * (y2 - y1) - cy;
    const ay = 1.0 - cy - by;

    function sampleCurveDerivativeX(t) {
      return (3.0 * ax * t + 2.0 * bx) * t + cx;
    }

    function sampleCurveX(t) {
      return ((ax * t + bx) * t + cx) * t;
    }

    function solveCurveX(x, epsilon) {
      let t0, t1, t2, x2, d2, i;
      for (t2 = x, i = 0; i < 8; i++) {
        x2 = sampleCurveX(t2) - x;
        if (Math.abs(x2) < epsilon) {
          return t2;
        }
        d2 = sampleCurveDerivativeX(t2);
        if (Math.abs(d2) < 1e-6) {
          break;
        }
        t2 = t2 - x2 / d2;
      }
      t0 = 0.0;
      t1 = 1.0;
      t2 = x;
      if (t2 < t0) {
        return t0;
      }
      if (t2 > t1) {
        return t1;
      }
      while (t0 < t1) {
        x2 = sampleCurveX(t2);
        if (Math.abs(x2 - x) < epsilon) {
          return t2;
        }
        if (x > x2) {
          t0 = t2;
        } else {
          t1 = t2;
        }
        t2 = (t1 - t0) * 0.5 + t0;
      }
      return t2; // Failure.
    }

    function solve(x, epsilon) {
      return sampleCurveY(solveCurveX(x, epsilon));
    }

    function sampleCurveY(t) {
      return ((ay * t + by) * t + cy) * t;
    }

    return solve(t, 1 / 200);
  }

  return cubicBezier;
}
