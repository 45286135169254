export const initCursorForSwiper = (cursorSelector, swiperSelector) => {
  let mouseX = -100,
    mouseY = -100,
    cursorX = -100,
    cursorY = -100;

  const cursor = document.querySelector(cursorSelector),
    swiperElements = document.querySelectorAll(swiperSelector);

  const lerpFactor = 0.1;

  const lerp = (start, end, factor) => ((1 - factor) * start) + (factor * end);

  const initializeCursorMovement = () => {
    if (!cursor) return;

    document.addEventListener('mousemove', event => {
      mouseX = event.clientX;
      mouseY = event.clientY;
    });

    const updateCursorPosition = () => {
      cursorX = lerp(cursorX, mouseX, lerpFactor);
      cursorY = lerp(cursorY, mouseY, lerpFactor);

      cursor.style.transform = `translate(${cursorX}px, ${cursorY}px)`;

      requestAnimationFrame(updateCursorPosition);
    };

    requestAnimationFrame(updateCursorPosition);
  };

  const showCursorInSwiper = () => cursor.classList.add('visible');

  const hideCursorOutsideSwiper = () => cursor.classList.remove('visible');

  const handleMouseEnterExcludedElement = event => {
    if (event.target.classList.contains('btn') || event.target.classList.contains('swiper-pagination-bullet')) cursor.classList.remove('visible');
  };

  const handleMouseLeaveExcludedElement = event => {
    if (event.target.classList.contains('btn') || event.target.classList.contains('swiper-pagination-bullet')) cursor.classList.add('visible');
  };

  const addEventListenersToSwiperCursor = swiperElement => {
    swiperElement.addEventListener('mouseenter', showCursorInSwiper);
    swiperElement.addEventListener('mouseleave', hideCursorOutsideSwiper);
    swiperElement.addEventListener('mouseenter', handleMouseEnterExcludedElement, true);
    swiperElement.addEventListener('mouseleave', handleMouseLeaveExcludedElement, true);
  };

  const initializeCursor = () => {
    swiperElements.forEach(swiperElement => addEventListenersToSwiperCursor(swiperElement));
    initializeCursorMovement();
  };

  initializeCursor();
};
